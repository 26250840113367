const queryKeys = {
  HOTEL_SEARCH_CONTROL: {
    hotelId: 'HotelId',
    itemId: 'ItemId',
    hotelCode: 'QueryHotelCD',
    countryId: 'QueryCtryID',
    areaId: 'QueryAreaID',
    resortId: 'QueryResID',
    actionName: 'ActionName',
    departureId: 'QueryDepID',
    departureDate: 'QueryDepDate',
    returnDate: 'QueryRetDate',
    duration: 'QueryDur',
    ages: 'QueryAges',
    roomDistribution: 'QueryRoomAges',
    type: 'QueryType',
  },
  BOOKING_START: {
    departureId: 'QueryDepID',
    countryId: 'QueryCtryID',
    areaId: 'QueryAreaID',
    resortId: 'QueryResID',
    destinationId: 'QueryDestID',
    departureDate: 'QueryDepDate',
    returnDate: 'QueryRetDate',
    checkInDate: 'QueryChkInDate',
    checkOutDate: 'QueryChkOutDate',
    duration: 'QueryDur',
    roomDistribution: 'QueryRoomAges',
    units: 'QueryUnits',
    categoryId: 'CategoryId',
    showPriceCalendar: 'showPriceCalendar',
    cabinClass: 'CabinClass',
  },
  COMBO_SEARCH_CONTROL: {
    departureId: 'QueryDepWvID',
    sessionId: 'SessionId',
    departureDate: 'QueryDepDate',
    roomDistribution: 'QueryRoomAges',
  },
  TDM_SEARCH_CONTROL: {
    departureId: 'QueryDepWvID',
    departureDate: 'QueryDepDate',
    roomDistribution: 'QueryRoomAges',
    sessionId: 'SessionId',
  },
};

export default queryKeys;
