import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useIsGlobetrotter, useIsGlobetrotterLegacy } from '@bwoty-web/ui-kit/hooks';
import './bookingstart.scss';

const BookingstartPlaceholder = ({ hideProductSelector, hideHeading, searchResultMode }) => {
  const classes = classNames(
    'bs-placeholder',
    {
      'bs-placeholder--globetrotter': useIsGlobetrotterLegacy(),
      'bs-placeholder--globetrotter2': useIsGlobetrotter(),
      'bs-placeholder--search-result-mode': searchResultMode,
      'bs-placeholder--no-top-container': !searchResultMode && hideHeading && hideProductSelector,
      'bs-placeholder--no-product-selector': !searchResultMode && hideProductSelector && !hideHeading,
    },
  );

  return (
    <div className={classes} />
  );
};

BookingstartPlaceholder.propTypes = {
  hideProductSelector: PropTypes.bool,
  hideHeading: PropTypes.bool,
  searchResultMode: PropTypes.bool,
};

BookingstartPlaceholder.defaultProps = {
  hideProductSelector: false,
  hideHeading: false,
  searchResultMode: false,
};

export default BookingstartPlaceholder;
