import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from '@bwoty-web/ui-kit';
import { useIsGlobetrotter, useIsGlobetrotterLegacy } from '@bwoty-web/ui-kit/hooks';

import { isSmallScreen } from '../../utils/device';
import BookingstartPlaceholder from '../placeholders/bookingstart';

import './searchResultView.scss';

const SearchResultView = ({ isLoading, labels, data, expand, isSticky }) => {
  const isGlobetrotter = useIsGlobetrotter();
  const isGlobetrotterLegacy = useIsGlobetrotterLegacy();

  const SearchResultViewClasses = classNames('bs-search-result-view', {
    'bs-search-result-view--sticky': isSticky,
    'bs-search-result-view--globetrotter': isGlobetrotterLegacy,
    'bs-search-result-view--globetrotter2': isGlobetrotter,
  });

  if (!data || isLoading) {
    return <BookingstartPlaceholder searchResultMode />;
  }

  return (
    <>
    {isSticky && (
      <div className="bs-search-result-view__sticky-compensate" />
    )}
    <div className={SearchResultViewClasses}>
      <div className="bs-search-result-view__left">
        <div className="bs-search-result-view__heading">
          <span className="bs-search-result-view__destination-name">
            {data.destination}
          </span>
          <span className="bs-search-result-view__country-name">
            {(data.country && data.country !== data.destination) && `, ${data.country}`}
          </span>
        </div>
        <div className="bs-search-result-view__travel-info">
          {data.departure && (
            <span>{data.departure},&nbsp;</span>
          )}
          <span>{data.duration},&nbsp;</span>
          <span>{data.pax}</span>
        </div>
      </div>
      <div className="bs-search-result-view__right">
        <Button
          variant="ghost"
          className="bs-search-result-view__expand-button"
          id="gtm-new-search"
          onClick={() => expand()}
        >
          {isSmallScreen() ? labels.changeSearchButtonMobileLabel : labels.changeSearchButtonLabel}
        </Button>
      </div>
    </div>
    </>
  );
};

SearchResultView.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  labels: PropTypes.shape({
    changeSearchButtonMobileLabel: PropTypes.string,
    changeSearchButtonLabel: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape({
    destination: PropTypes.string,
    country: PropTypes.string,
    departure: PropTypes.string,
    duration: PropTypes.string,
    pax: PropTypes.string,
  }),
  expand: PropTypes.func.isRequired,
  isSticky: PropTypes.bool.isRequired,
};

SearchResultView.defaultProps = {
  data: null,
};


export default SearchResultView;
