import moment from 'moment';
import PropTypes from 'prop-types';
import { Icon } from '@bwoty-web/ui-kit';
import { useState } from 'react';
import { UI_CHEVRON_RIGHT } from '@bwoty-web/ui-kit/icons';

import { isSmallScreen } from '../../utils/device';

import './latestSearches.scss';

const LatestSearches = ({ labels, searches, dateSettings }) => {
  if (!searches || searches.length < 1) {
    return null;
  }

  const capitalize = (value) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  const [showAllSearches, setShowAllSearches] = useState(!isSmallScreen());

  return (
    <div className="bs-latest-searches">
      <div className="bs-latest-searches__heading">
        {labels.latestSearchesLabel}
        {(!showAllSearches && searches.length > 1) && (
          <button
            type="button"
            className="bs-latest-searches__expand-button"
            onClick={() => setShowAllSearches(true)}
          >
            {labels.showMoreLabel}
          </button>
        )}
      </div>
      <div className="bs-latest-searches__items">
        {searches.map((search, index) => {
          if (!showAllSearches && index > 0) {
            return null;
          }

          const { destinationName, destinationParentName, startDate, endDate, departureMonths } = search;
          const hasParent = destinationParentName && destinationParentName !== '';

          const destination = `${destinationName}${(hasParent !== '' ? `, ${destinationParentName}` : '')}`;

          let duration;
          if (departureMonths != null && departureMonths.length > 0) {
            duration = departureMonths.map((date) => {
              const dateMoment = moment(date, 'YYYYMM');
              const month = dateSettings.months[dateMoment.month()];
              return `${capitalize(month)} ${dateMoment.year()}`;
            }).join(', ');
          } else {
            duration = startDate + (endDate !== '' ? ` - ${endDate}` : '');
          }

          return (
            <a
              className="bs-latest-searches__item-link"
              href={search.searchResultUrl}
            >
              <div className="bs-latest-searches__destination">
                {destination}
              </div>
              <div className="bs-latest-searches__duration">
                {duration}
              </div>
              <Icon
                className="bs-latest-searches__item-arrow"
                path={UI_CHEVRON_RIGHT}
                size="xs"
              />
            </a>
          );
        })}
      </div>
    </div>
  );
};

LatestSearches.propTypes = {
  labels: PropTypes.shape({
    latestSearchesLabel: PropTypes.string,
    showMoreLabel: PropTypes.string,
  }),
  searches: PropTypes.arrayOf(PropTypes.shape({
    searchResultUrl: PropTypes.string,
    destinationName: PropTypes.string,
    destinationParentName: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  })),
  dateSettings: PropTypes.shape({
    months: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

LatestSearches.defaultProps = {
  labels: {},
  searches: [],
};

export default LatestSearches;
