import moment from 'moment';
import store from '../../stores/bookingstart';
import * as packageService from '../../../services/package';
import { ActionTypes } from '../../constants/actionTypes/bookingstart';
import bookingTypes from '../../../constants/bookingTypes';
import {
  getSelectedDuration,
  getSelectedDepartureDate,
  getSelectedPackageDestination,
  getPriceCalendarDataKey,
  getSelectedOffer,
} from '../../../utils/actionHelper';

const getExtendedSearchOptions = (selectedValues) => {
  return async (dispatch) => {
    const {
      selectedDepartureAirport,
      selectedDestination,
    } = selectedValues;

    // Get extended search options
    const { siteSettings } = store.getState().bookingstart;
    const { siteId, baseUrl } = siteSettings;
    const departureAirportId = selectedDepartureAirport.itemId;
    const destinationId = selectedDestination.itemId;

    const options = await packageService.getExtendedSearchOptions(
      baseUrl,
      siteId,
      departureAirportId,
      destinationId,
      destinationId,
    );

    dispatch({
      type: ActionTypes.PKG_GET_EXTENDED_SEARCH_OPTIONS_SUCCESS,
      options,
    });
  }
};

const getCalendarPrices = (selectedValues) => {
  return async (dispatch) => {
    const {
      selectedDepartureAirport,
      selectedDestination,
      selectedDuration,
      showPriceCalendar,
      showDirectFlights,
    } = selectedValues;

    const shouldFetchPrices = showPriceCalendar && selectedDuration.hasCalendar;
    if (!shouldFetchPrices) {
      return;
    }

    // Get calendar prices
    const { siteSettings } = store.getState().bookingstart;
    const { priceCalendarData } = store.getState().package;

    const departureAirportId = selectedDepartureAirport.itemId;
    const destinationId = selectedDestination.itemId;
    const priceCalendarKey = getPriceCalendarDataKey(departureAirportId, destinationId, 0, selectedDuration, showDirectFlights);
    // Fetch prices if not fetched previously.
    if (!priceCalendarData[priceCalendarKey]) {
      const minDepDate = moment().add(siteSettings.datePicker.dpBookDaysAhead, 'days');
      const maxDepDate = moment().add(siteSettings.datePicker.dpMaxDaysToReturn, 'days');
      let dateToFetch = minDepDate;
      const tempPriceCalendarData = {};
      while (!dateToFetch.isAfter(maxDepDate)) {
        // eslint-disable-next-line no-await-in-loop
        const data = await packageService.getPriceCalendar(
          departureAirportId,
          destinationId,
          destinationId,
          0,
          dateToFetch.format('YYYY-MM-DD'),
          selectedDuration.defaultDuration + 1,
          showDirectFlights,
        );
        if (data && data.calendars && data.calendars.length > 0) {
          data.calendars.forEach((calendarMonth) => {
            const dataMonth = calendarMonth.month;
            tempPriceCalendarData[dataMonth] = calendarMonth;
          });
          if (data.calendars.length < 4) {
            // We should get calendar for 4 months in each request.
            // If less than 4 months is returned, then no more calendar data exists and we should stop fetching new data.
            break;
          }
          const lastFetchedCalenderMonth = moment(data.calendars[data.calendars.length - 1].month);
          if (!lastFetchedCalenderMonth.isAfter(dateToFetch)) {
            break;
          }
          dateToFetch = lastFetchedCalenderMonth.add(1, 'M');
        } else {
          break;
        }
      }

      priceCalendarData[priceCalendarKey] = [];
      Object.keys(tempPriceCalendarData).forEach((key) => {
        priceCalendarData[priceCalendarKey].push(tempPriceCalendarData[key]);
      });
      dispatch({
        type: ActionTypes.PKG_GET_PRICE_CALENDAR_DATA_SUCCESS,
        priceCalendarData,
      });
    }
  }
};

export const initPackageSection = (preSelectedSettings) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.PKG_INIT_SECTION,
    });

    const data = await packageService.getInitState(preSelectedSettings);
    
    dispatch({
      type: ActionTypes.PKG_INIT_SECTION_SUCCESS,
      data,
      showPriceCalendar: preSelectedSettings.showPriceCalendar,
      showDirectFlights: preSelectedSettings.showDirectFlights,
    });


    if (preSelectedSettings.showPriceCalendar) {
      data.showPriceCalendar = preSelectedSettings.showPriceCalendar;
      data.showDirectFlights = preSelectedSettings.showDirectFlights;
      dispatch(getCalendarPrices(data));
    }

    dispatch(getExtendedSearchOptions({
      selectedDepartureAirport: data.selectedDeparture,
      selectedDestination: data.selectedResort,
    }));
  };
};

export const getDepartureDates = (selectedValues) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.PKG_GET_DEPARTURE_DATES,
    });

    const { siteSettings } = store.getState().bookingstart;
    const { systemDateFormat, dpBookDaysAhead } = siteSettings.datePicker;

    const {
      selectedDepartureAirport,
      selectedDestination,
      selectedDuration,
      selectedDepartureDate,
      selectedReturnDate,
    } = selectedValues;

    const { bookingType, defaultDuration, flexibleDuration } = selectedDuration;

    if (bookingType === bookingTypes.charter) {
      const departureDates = await packageService.getCharterDepartureDates(
        selectedDepartureAirport.itemId,
        selectedDestination.countryId,
        selectedDestination.itemId,
        defaultDuration,
      );

      const durationLength = defaultDuration !== -1 ? defaultDuration - 1 : 3;
      const departureDate = getSelectedDepartureDate(departureDates, selectedDepartureDate);
      const returnDate = moment(departureDate).add(durationLength, 'days').format(systemDateFormat);

      dispatch({
        type: ActionTypes.PKG_GET_DEPARTURE_DATES_SUCCESS,
        departureDates,
        selectedDepartureDate: departureDate,
        selectedReturnDate: returnDate,
      });
    } else {
      const departureDates = await packageService.getIndependentDepartureDates(
        selectedDepartureAirport.itemId,
        selectedDestination.countryId,
        selectedDestination.itemId,
        selectedDuration.defaultDuration,
        selectedDuration.flexibleDuration,
      );

      const firstPossibleBookingDayDp = moment().add(dpBookDaysAhead, 'days');

      let departureDate = moment(selectedDepartureDate);
      let returnDate = moment(selectedReturnDate);

      if (flexibleDuration) {
        if (departureDate.isBefore(firstPossibleBookingDayDp)) {
          departureDate = moment(firstPossibleBookingDayDp).format(systemDateFormat);
          returnDate = moment(departureDate).add(defaultDuration, 'days').format(systemDateFormat);
        }
      } else {
        departureDate = moment(selectedDepartureDate).format(systemDateFormat);
        returnDate = moment(selectedDepartureDate).add(defaultDuration, 'days').format(systemDateFormat);
      }

      dispatch({
        type: ActionTypes.PKG_GET_DEPARTURE_DATES_SUCCESS,
        departureDates,
        selectedDepartureDate: departureDate,
        selectedReturnDate: returnDate,
      });
    }
  };
};

export const setPreviousDuration = (previousDuration) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.PKG_SET_PREVIOUS_DURATION,
      previousDuration,
    });
  }
}

export const getDurations = (selectedValues) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.PKG_GET_DURATIONS,
    });

    const {
      selectedDepartureAirport,
      selectedDestination,
      selectedDuration,
    } = selectedValues;

    const durationsData = await packageService.getDurations(
      selectedDepartureAirport.itemId,
      selectedDestination.countryId,
      selectedDestination.itemId,
    );

    const durations = durationsData ? durationsData.durations : [];
    const duration = getSelectedDuration(durationsData.durations, selectedDuration);
    const options = durationsData ? durationsData.options : [];

    dispatch({
      type: ActionTypes.PKG_GET_DURATIONS_SUCCESS,
      durations,
      selectedDuration: duration,
      options,
    });

    dispatch(setPreviousDuration(null));

    const newSelectedValues = {
      ...selectedValues,
      selectedDuration: duration,
    };

    dispatch(getDepartureDates(newSelectedValues));
    dispatch(getCalendarPrices(newSelectedValues));
    dispatch(getExtendedSearchOptions(selectedValues));
  };
};

export const getCountries = (selectedValues) => {
  return async (dispatch) => {
    const { selectedDepartureAirport, selectedDestination } = selectedValues;

    dispatch({
      type: ActionTypes.PKG_GET_COUNTRIES,
    });

    const countries = await packageService.getCountries(selectedDepartureAirport.itemId);
    const destination = getSelectedPackageDestination(countries, selectedDestination);

    dispatch({
      type: ActionTypes.PKG_GET_COUNTRIES_SUCCESS,
      countries,
      selectedDestination: destination,
    });

    const newSelectedValues = {
      ...selectedValues,
      selectedDestination: destination,
    };

    dispatch(getDurations(newSelectedValues));
  };
};

export const searchDestinations = (departureId, searchTerm) => {
  return async (dispatch) => {
    const searchResults = await packageService.searchDestinations(departureId, searchTerm);

    dispatch({
      type: ActionTypes.PKG_SEARCH_DESTINATIONS_SUCCESS,
      searchResults,
    });
  };
};

export const changeDepartureAirport = (airport) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.PKG_CHANGE_DEPARTURE_AIRPORT,
      airport,
    });

    const selectedValues = {
      ...store.getState().package,
      selectedDepartureAirport: airport,
    };

    dispatch(getCountries(selectedValues));
    dispatch(getCalendarPrices(selectedValues));
  };
};

export const changeDestination = (destination) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.PKG_CHANGE_DESTINATION,
      destination,
    });

    const selectedValues = {
      ...store.getState().package,
      selectedDestination: destination,
    };

    dispatch(getDurations(selectedValues));
    dispatch(getCalendarPrices(selectedValues));
  };
};

export const changeDuration = (duration, clearPreviousDuration = true) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.PKG_CHANGE_DURATION,
      duration,
    });

    if (clearPreviousDuration) {
      dispatch(setPreviousDuration(null));
    }

    const selectedValues = {
      ...store.getState().package,
      selectedDuration: duration,
    };

    dispatch(getDepartureDates(selectedValues));
    dispatch(getCalendarPrices(selectedValues));
  };
};

export const changeDepartureDate = (date) => {
  return (dispatch) => {
    const { siteSettings } = store.getState().bookingstart;
    const { systemDateFormat } = siteSettings.datePicker;
    const {
      selectedDuration,
      selectedDepartureAirport,
      showPriceCalendar,
      selectedDestination,
      showDirectFlights,
      priceCalendarData,
    } = store.getState().package;
    const { defaultDuration, bookingType } = selectedDuration;
    let durationLength = null;

    if (bookingType === bookingTypes.charter) {
      durationLength = defaultDuration !== -1 ? defaultDuration - 1 : 3;
    } else {
      durationLength = defaultDuration !== -1 ? defaultDuration : 3;
    }

    const returnDate = moment(date).add(durationLength, 'days').format(systemDateFormat);
    let selectedOffer = null;

    if (showPriceCalendar) {
      const key = getPriceCalendarDataKey(
        selectedDepartureAirport.itemId,
        selectedDestination.itemId,
        0,
        selectedDuration,
        showDirectFlights,
      );
      
      selectedOffer = getSelectedOffer(key, priceCalendarData, date);
    }

    dispatch({
      type: ActionTypes.PKG_CHANGE_DEPARTURE_DATE,
      date,
      selectedReturnDate: returnDate,
      selectedOffer,
    });
  };
};

export const changeDates = (departureDate, returnDate) => {
  return {
    type: ActionTypes.PKG_CHANGE_DATES,
    departureDate,
    returnDate,
  };
};

export const changeRoomDistribution = (roomDistribution, selectedNumberOfRooms, isSameRoom) => {
  return {
    type: ActionTypes.PKG_CHANGE_ROOM_DISTRIBUTION,
    roomDistribution,
    selectedNumberOfRooms,
    isSameRoom,
  };
};

export const toggleEveryoneInSameRoomChecked = (isEveryoneInSameRoomChecked) => {
  return {
    type: ActionTypes.PKG_TOGGLE_EVERYONE_IN_SAME_ROOM_CHECKED,
    isEveryoneInSameRoomChecked: !isEveryoneInSameRoomChecked,
  };
};

export const changeDepartureMonths = (months) => {
  const { departureDates, selectedDuration: duration } = store.getState().package;
  const { siteSettings } = store.getState().bookingstart;
  const { systemDateFormat } = siteSettings.datePicker;
  const [firstMonth] = months;

  const departureDate = getSelectedDepartureDate(departureDates, firstMonth);
  const returnDate = moment(departureDate)
    .add(duration.defaultDuration, 'days')
    .format(systemDateFormat);

  return {
    type: ActionTypes.PKG_CHANGE_DEPARTURE_MONTHS,
    departureMonths: months,
    departureDate,
    returnDate,
  };
};

export const setShowPriceCalendar = (showPriceCalendar) => {
  return async (dispatch) => {
    dispatch ({
      type: ActionTypes.PKG_SET_SHOW_PRICE_CALENDAR,
      showPriceCalendar,
    });

    const selectedValues = {
      ...store.getState().package,
      showPriceCalendar,
    }

    dispatch(getCalendarPrices(selectedValues));
  }
};

export const setShowDirectFlights = (showDirectFlights) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.PKG_SET_SHOW_DIRECT_FLIGHTS,
      showDirectFlights,
    });

    const selectedValues = {
      ...store.getState().package,
      showDirectFlights,
    };

    dispatch(getCalendarPrices(selectedValues));
  };
};

export const setSearchDirectOnly = (searchDirectOnly) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.PKG_SET_SEARCH_DIRECT_ONLY,
      searchDirectOnly,
    });
  };
};

export const setCabinClass = (cabinclass) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.PKG_SET_CABINCLASS,
      cabinclass,
    })
  }
}


export const setSearchLuggageIncluded = (searchLuggageIncluded) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.PKG_SET_SEARCH_LUGGAGE_INCLUDED,
      searchLuggageIncluded,
    });
  };
};

export const setShowDateLayer = (showDateLayer) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.PKG_SET_SHOW_DATE_LAYER,
      showDateLayer,
    })
  }
}

export const showDateLayerWithPriceCalendar = () => {
  return (dispatch) => {
    const { hasCalendar } = store.getState().package.selectedDuration;
    if (hasCalendar) dispatch(setShowPriceCalendar(true));
    dispatch(setShowDateLayer(true));
  };
};