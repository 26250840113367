import { tryCatch } from '@bwoty-web/utils';
import { fetchJsonByProxy, fetchJson } from '../utils/fetch';

let bsApiUrls = null;

export const getBsInitData = async (siteId, apiUrl, cookieId, baseUrl) => {
  const checkedCookieId = (cookieId && cookieId.length > 0) ? cookieId : '-';

  const url = `${apiUrl}/bs/api/bsinit/${siteId}/${checkedCookieId}`;
  const bsUrls = {};

  const [error, json] = await tryCatch(async () => {
    const response = await fetchJsonByProxy(url, baseUrl);
    return response;
  });

  if (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  // Prepare api urls with siteId
  Object.keys(json.bsApiInterface).forEach((propertyName) => {
    bsUrls[propertyName] = `${baseUrl}/api/proxy?url=${json.bsApiInterface[propertyName]
      .replace('{siteId}', siteId)
      .replace('{cookieId}', checkedCookieId)}`;
  });

  bsApiUrls = bsUrls;

  return json;
};

export const getApiUrls = () => {
  return new Promise((resolve) => {
    if (bsApiUrls != null) {
      resolve(bsApiUrls);
    }
  });
};

export const getSearchUrlAndStoreSearch = async (sectionData, baseUrl) => {
  const apiUrls = await getApiUrls();
  const url = apiUrls.storeSearchAndGetUrl
    .replace('{departureId}', sectionData.depId)
    .replace('{destinationId}', sectionData.destId)
    .replace('{countryId}', sectionData.ctryId)
    .replace('{areaId}', sectionData.areaId)
    .replace('{resortId}', sectionData.resId)
    .replace('{duration}', sectionData.dur)
    .replace('{departureDate}', sectionData.depDate)
    .replace('{returnDate}', sectionData.retDate)
    .replace('{departureMonths}', sectionData.depMonths)
    .replace('{roomAges}', sectionData.roomAges)
    .replace('{sameRoom}', sectionData.sameRoom)
    .replace('{searchCharter}', sectionData.searchCharter)
    .replace('{categoryId}', sectionData.categoryId)
    .replace('{categoryFilterId}', sectionData.categoryFilterId);

  const [error, response] = await tryCatch(async () => {
    const response = await fetch(url);
    return response.json();
  });

  if (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return response;
};
