import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import classNames from 'classnames';
import { Stepper, Button, Select, Checkbox, RadioButton } from '@bwoty-web/ui-kit';
import { useIsGlobetrotter } from '@bwoty-web/ui-kit/hooks';
import SelectOverlay from '../selectOverlay/SelectOverlay';
import { isSmallScreen } from '../../utils/device';
import { maxNumberOfRooms, childAgeMax } from '../../constants/pax';
import {
  getTotalNumberOfAdults,
  getTotalNumberOfChildren,
  getTotalNumberOfPax,
  getNumberOfPaxAvailable,
} from '../../utils/pax/helper';
import {
  isValidAge,
  validRoomDistribution,
  validAges,
  validNumberOfInfants,
  validNumberOfAdultsInRoom,
} from '../../utils/pax/validation';

import './paxSelect.scss';


const emptyRoom = {
  numberOfAdults: 0,
  numberOfChildren: 0,
  childAges: [],
};

const PaxSelect = (props) => {
  const {
    labels,
    roomDistribution,
    close,
    multipleRooms,
    numberOfRooms,
    maxNumberOfPax,
    showManyPaxLink,
    preferAllPaxInSameRoom,
    changeRoomDistribution,
    setAllPaxInSameRoomFunc,
    activeValidation,
    isCruise,
    hideSameRoomCheckbox,
    showCabinClasses,
    selectedCabinClass,
    setCabinClass,
    isDP,
  } = props;

  const {
    paxLayerHeading,
    roomLabel,
    adultPluralText,
    yearText,
    chooseText,
    paxConfirmLabel,
    childSingularText,
    childPluralText,
    addRoomLabel,
    removeRoomLabel,
    everyoneInSameRoom: everyoneInSameRoomLabel,
    infantValidationText,
    invalidChildaAgeMessage,
    cruiseNumberOfPersonLabel,
    cruiseNumberOfPersonPreamble,
    cruiseAgeLabel,
    cruiseAgePreamble,
    adultSelectSubLabel,
    numberOfAdultsInRoomMessage,
    manyPaxLink,
    manyPaxLinkText,
  } = labels;

  const isGlobetrotter = useIsGlobetrotter();

  const getAllChildAges = () => {
    const ages = [];

    roomDistribution.forEach((room) => {
      ages.push(...room.childAges);
    });

    return ages;
  };

  const getInitialRoomDistribution = () => {
    if (multipleRooms) {
      return [...roomDistribution];
    }

    return [
      {
        numberOfAdults: getTotalNumberOfAdults(roomDistribution),
        numberOfChildren: getTotalNumberOfChildren(roomDistribution),
        childAges: getAllChildAges(),
      },
      { ...emptyRoom },
      { ...emptyRoom },
    ];
  };

  const [roomCount, setRoomCount] = useState(numberOfRooms);
  const [rooms, setRooms] = useState(getInitialRoomDistribution());
  const [allPaxInSameRoom, setAllPaxInSameRoom] = useState(preferAllPaxInSameRoom);

  useEffect(() => {
    setAllPaxInSameRoomFunc(() => setAllPaxInSameRoom(!allPaxInSameRoom));
  }, [allPaxInSameRoom]);

  const sameRoomCheckboxOnFocus = () => {
    document.getElementById('same-room').parentElement.getElementsByTagName('label')[0].getElementsByTagName('span')[0].classList.add('bs-pax-same-room__hover');
  };

  const sameRoomCheckboxOnBlur = () => {
    document.getElementById('same-room').parentElement.getElementsByTagName('label')[0].getElementsByTagName('span')[0].classList.remove('bs-pax-same-room__hover');
  };

  const addRoom = () => {
    if (rooms.length < maxNumberOfRooms) {
      const newRoomDistribution = [...rooms];
      newRoomDistribution.push({ ...emptyRoom });
      setRooms(newRoomDistribution);
    }
    setRoomCount(roomCount + 1);
  };

  const removeRoom = (roomIndex) => {
    const newRoomDistribution = [...rooms];
    newRoomDistribution.splice(roomIndex, 1);
    setRoomCount(roomCount - 1);
    setRooms(newRoomDistribution);
  };

  const changeNumberOfAdults = (count, roomIndex) => {
    const newRoomDistribution = [...rooms];
    newRoomDistribution[roomIndex].numberOfAdults = count;
    setRooms(newRoomDistribution);
  };

  const changeNumberOfChildren = (count, roomIndex) => {
    const newRoomDistribution = [...rooms];
    newRoomDistribution[roomIndex].numberOfChildren = count;
    setRooms(newRoomDistribution);
  };

  const changeChildAge = (age, roomIndex, childIndex) => {
    const newRoomDistribution = [...rooms];
    newRoomDistribution[roomIndex].childAges[childIndex] = age;
    setRooms(newRoomDistribution);
  };

  const childAgeOnFocus = (childIndex) => {
    document.getElementsByClassName('bs-pax-select-room__child-age')[childIndex]
      .getElementsByClassName('sg-list-box')[0]
      .classList.add('bs-list-box__hover');
  };

  const childAgeOnBlur = (childIndex) => {
    document.getElementsByClassName('bs-pax-select-room__child-age')[childIndex]
      .getElementsByClassName('sg-list-box')[0]
      .classList.remove('bs-list-box__hover');
  };

  const getChildAgesList = () => {
    return Array.from(
      { length: childAgeMax + 1 },
      (v, age) => {
        return ({
          value: age,
          name: `${age} ${yearText}`,
        });
      }
    );
  };

  const confirm = () => {
    const sameRoom = getTotalNumberOfPax(rooms) < 7 ? allPaxInSameRoom : false;
    changeRoomDistribution(rooms, roomCount, sameRoom);
  };

  const ConfirmButton = () => {
    return (
      <Button
        variant={isSmallScreen() ? 'primary' : 'secondary'}
        disabled={!validRoomDistribution(rooms)}
        onClick={() => confirm()}
        rel="nofollow"
        className="bs-pax-select-confirm-button"
      >
        {paxConfirmLabel}
      </Button>
    );
  };

  const ValidationMessage = () => {
    if (!activeValidation || validRoomDistribution(rooms)) {
      return null;
    }

    const invalidChildAges = !validAges(rooms);
    const invalidNumberOfInfants = !validNumberOfInfants(rooms);
    const invalidNumberOfAdultsInRoom = !validNumberOfAdultsInRoom(rooms);
    return (
      <div className="bs-pax-select-validation">
        { invalidChildAges && (
          <div className="bs-pax-select-validation__message">
            {invalidChildaAgeMessage}
          </div>
        )}
        { invalidNumberOfInfants && (
          <div className="bs-pax-select-validation__message">
            {infantValidationText}
          </div>
        )}
        { invalidNumberOfAdultsInRoom && (
          <div className="bs-pax-select-validation__message">
            {numberOfAdultsInRoomMessage}
          </div>
        )}
      </div>
    );
  };

  const showAddRoomButton = multipleRooms && roomCount < maxNumberOfRooms;
  const classes = classNames('bs-pax-select', {
    'bs-pax-select--multiple-rooms': multipleRooms,
  });

  return (
    <SelectOverlay
      heading={paxLayerHeading}
      close={() => close()}
      clickedOutside={event => confirm(event)}
      footerContent={(
        <>
          <ConfirmButton />
        </>
      )}
    >
      <div className={classes}>
        <h2 className="bs-pax-select__pax-heading select-overlay__heading">
          {paxLayerHeading}
        </h2>
        <div className="bs-pax-select__main-content">
          <div className="bs-pax-select-rooms">
            {[...Array(multipleRooms ? roomCount : 1)].map((a, roomIndex) => {
              const room = rooms[roomIndex];
              const { numberOfAdults, numberOfChildren } = room;

              const adultsMinValue = roomIndex === 0 ? 1 : 0;
              const childrenMinValue = 0;
              const adultsMaxValue = getNumberOfPaxAvailable(rooms, maxNumberOfPax) + numberOfAdults;
              const childrenMaxValue = getNumberOfPaxAvailable(rooms, maxNumberOfPax) + numberOfChildren;
              const showSameRoomCheckbox = (
                !multipleRooms
                && (getTotalNumberOfPax(rooms) > 2)
                && (getTotalNumberOfPax(rooms) < 7)
                && !hideSameRoomCheckbox
              );
              const showRemoveButton = roomIndex !== 0;

              return (
                <div
                  className="bs-pax-select-room"
                  key={uniqid('room-')}
                >
                  { multipleRooms && (
                    <div className="bs-pax-select-room__top-container">
                      <div className="bs-pax-select-room__label">
                        {`${roomLabel} ${roomIndex + 1}`}
                      </div>
                      { showRemoveButton && (
                        <button
                          className="bs-pax-select-room__remove-button"
                          type="button"
                          onClick={() => removeRoom(roomIndex)}
                        >
                          {removeRoomLabel}
                        </button>
                      )}
                    </div>
                  )}
                  <div className="bs-pax-select-room__steppers">
                    <div className="bs-pax-select-room__stepper-container">
                      <Stepper
                        title={adultPluralText}
                        subtitle={adultSelectSubLabel}
                        minValue={adultsMinValue}
                        maxValue={adultsMaxValue}
                        value={numberOfAdults}
                        onChange={count => changeNumberOfAdults(count, roomIndex)}
                      />
                    </div>
                    <div className="bs-pax-select-room__stepper-container">
                      <Stepper
                        title={childPluralText}
                        subtitle={`0-17 ${yearText}`}
                        minValue={childrenMinValue}
                        maxValue={childrenMaxValue}
                        value={numberOfChildren}
                        onChange={count => changeNumberOfChildren(count, roomIndex)}
                      />
                    </div>
                  </div>
                  { numberOfChildren > 0 && (
                    <div className="bs-pax-select-room__children-ages">
                      {[...Array(numberOfChildren)].map((b, childIndex) => {
                        const childAge = parseInt(room.childAges[childIndex], 10);
                        const isInvalid = !isValidAge(childAge);
                        const childAgesList = getChildAgesList();
                        const childItemId = `${childSingularText}_${childIndex + 1}`;
                        return (
                          <div className="bs-pax-select-room__child-age" key={childItemId}>
                            <label
                              for={childItemId}
                              className="bs-pax-select-room__child-age-label"
                            >
                              {`${childSingularText} ${childIndex + 1}`}
                            </label>
                            <Select
                              className="bs-pax-select-room__child-age-select"
                              items={getChildAgesList()}
                              defaultValue={isInvalid ? chooseText || 'Välj' : childAgesList[childAge]}
                              value={childAgesList[childAge]}
                              onChange={e => changeChildAge(e.target.value, roomIndex, childIndex)}
                              invalid={activeValidation && isInvalid}
                              onFocus={() => childAgeOnFocus(childIndex)}
                              onBlur={() => childAgeOnBlur(childIndex)}
                              id={childItemId}
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                  { showSameRoomCheckbox && (
                    <Checkbox
                      className="bs-pax-select-room__prefer-all-in-same-room"
                      checked={allPaxInSameRoom}
                      onChange={() => setAllPaxInSameRoom(!allPaxInSameRoom)}
                      uniqueId="same-room"
                      onFocus={() => sameRoomCheckboxOnFocus()}
                      onBlur={() => sameRoomCheckboxOnBlur()}
                    >
                      {everyoneInSameRoomLabel}
                    </Checkbox>
                  )}
                </div>
              );
            })}
          </div>
          {showManyPaxLink && manyPaxLink && manyPaxLinkText && getTotalNumberOfPax(rooms) > 5 && (
            <a
              className="bs-pax-select-many-pax-link"
              href={manyPaxLink}
              type="button"
            >
              {manyPaxLinkText}
            </a>
          )}
          <ValidationMessage />
          { isCruise && (
            <div className="bs-pax-select-cruise-info-container">
              <div className="bs-pax-select-cruise-info">
                <div className="bs-pax-select-cruise-info__header select-overlay__heading">{cruiseNumberOfPersonLabel}</div>
                <div className="bs-pax-select-cruise-info__preamble">{cruiseNumberOfPersonPreamble}</div>
              </div>
              <div className="bs-pax-select-cruise-info">
                <div className="bs-pax-select-cruise-info__header select-overlay__heading">{cruiseAgeLabel}</div>
                <div className="bs-pax-select-cruise-info__preamble">{cruiseAgePreamble}</div>
              </div>
            </div>
          )}
          { showAddRoomButton && isSmallScreen() && !isGlobetrotter && (
            <Button
              className="bs-pax-select-add-room-button"
              onClick={() => addRoom()}
            >
              {addRoomLabel}
            </Button>
          )}
          { (showAddRoomButton && (!isSmallScreen() || isGlobetrotter)) && (
            <button
              className="bs-pax-select-add-room-button-desktop"
              onClick={() => addRoom()}
              type="button"
            >
              {addRoomLabel}
            </button>
          )}
        </div>
      </div>
      {!!showCabinClasses?.length && isDP && (
        <div className="bs-pax-select__flight-class">
          <h2 className="bs-pax-select__flight-class-heading select-overlay__heading">
            {labels.flightClass || 'Flightclass'}
          </h2>
          <div className="bs-pax-select__flight-classes">
            {showCabinClasses.map((name) => (
              <RadioButton
                onChange={() =>
                  setCabinClass(name)
                }
                name="cabinclass"
                checked={name === selectedCabinClass}
                key={name}
                uniqueId={`cabinclass_${name}`}
                style={{ marginBottom: '20px' }}
              >
                {labels[`class${name}`] || name}
              </RadioButton>
            ))}
          </div>
        </div>
      )}
    </SelectOverlay>
  );
};

PaxSelect.propTypes = {
  roomDistribution: PropTypes.arrayOf(PropTypes.shape({
    numberOfAdults: PropTypes.number,
    numberOfChildren: PropTypes.number,
    childAges: PropTypes.arrayOf(PropTypes.number),
  })).isRequired,
  multipleRooms: PropTypes.bool,
  numberOfRooms: PropTypes.number.isRequired,
  maxNumberOfPax: PropTypes.number.isRequired,
  showManyPaxLink: PropTypes.bool,
  preferAllPaxInSameRoom: PropTypes.bool,
  changeRoomDistribution: PropTypes.func.isRequired,
  setAllPaxInSameRoomFunc: PropTypes.func,
  close: PropTypes.func.isRequired,
  activeValidation: PropTypes.bool,
  isCruise: PropTypes.bool,
  hideSameRoomCheckbox: PropTypes.bool,
  labels: PropTypes.shape({
    paxLayerHeading: PropTypes.string,
    roomLabel: PropTypes.string,
    adultPluralText: PropTypes.string,
    childPluralText: PropTypes.string,
    yearText: PropTypes.string,
    chooseText: PropTypes.string,
    paxConfirmLabel: PropTypes.string,
    childSingularText: PropTypes.string,
    addRoomLabel: PropTypes.string,
    removeRoomLabel: PropTypes.string,
    everyoneInSameRoom: PropTypes.string,
    infantValidationText: PropTypes.string,
    invalidChildaAgeMessage: PropTypes.string,
    cruiseNumberOfPersonLabel: PropTypes.string,
    cruiseNumberOfPersonPreamble: PropTypes.string,
    cruiseAgeLabel: PropTypes.string,
    cruiseAgePreamble: PropTypes.string,
    adultSelectSubLabel: PropTypes.string,
    numberOfAdultsInRoomMessage: PropTypes.string,
    manyPaxLink: PropTypes.string,
    manyPaxLinkText: PropTypes.string,
    flightClass: PropTypes.string,
    classEconomy: PropTypes.string,
    classEconomyPremium: PropTypes.string,
    classBusiness: PropTypes.string,
    classFirstClass: PropTypes.string,
  }).isRequired,
  showCabinClasses: PropTypes.arrayOf(PropTypes.string),
  selectedCabinClass: PropTypes.string,
  setCabinClass: PropTypes.func,
  isDP: PropTypes.bool,
};

PaxSelect.defaultProps = {
  preferAllPaxInSameRoom: false,
  activeValidation: false,
  isCruise: false,
  multipleRooms: false,
  showManyPaxLink: false,
  hideSameRoomCheckbox: false,
  setAllPaxInSameRoomFunc: () => {},
  showCabinClasses: null,
  selectedCabinClass: null,
  setCabinClass: () => {},
  isDP: false,
};

export default PaxSelect;
