import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import FormField from '../formField/FormField';
import FormFieldLoader from '../formField/FormFieldLoader';

const DepartureField = (props) => {
  const { className, labels, isLoading, isActive, isOtherFieldActive, toggleOverlay, selectedAirport } = props;

  if (isLoading) {
    return (
      <div className="bs-form__select bs-form__departure-select">
        <FormFieldLoader label={labels.departureInputLabel} />
      </div>
    );
  }

  const classNames = ClassNames(
    'bs-form__select',
    'bs-form__departure-select',
    className,
  );

  return (
    <div className={classNames}>
      <FormField value={selectedAirport.name} label={labels.departureInputLabel} click={() => toggleOverlay()} isActive={isActive} isOtherFieldActive={isOtherFieldActive} />

      {isActive ? props.children : null}
    </div>
  );
};

DepartureField.propTypes = {
  labels: PropTypes.object,
  isLoading: PropTypes.bool,
  isActive: PropTypes.bool,
  isOtherFieldActive: PropTypes.bool,
  toggleOverlay: PropTypes.func,
  selectedAirport: PropTypes.object,
};

export default DepartureField;
